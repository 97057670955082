import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { Countries } from 'src/app/models/enum/countries';
import { ViewPlans } from 'src/app/models/enum/view-plans';
import { NewPlansApplication } from 'src/app/models/interface/common/new-plans.application';
import { CoverageCategoryOws } from 'src/app/models/product/coverage-category-ows';
import { PriceDto } from 'src/app/models/product/price-dto';
import { ProductPolicy } from 'src/app/models/product/product-policy';
import { ProductVisit } from 'src/app/models/product/product-visit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';
import { PriceService } from 'src/app/services/price/price.service';
import { ProductPolicyService } from 'src/app/services/product-policy/product-policy.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'view-plans',
  templateUrl: './view-plans.component.html',
  styleUrls: ['./view-plans.component.scss']
})
export class ViewPlansComponent implements OnInit {

  bottonItems: NewPlansApplication[];
  selectedNewPlans: NewPlansApplication;
  selectTypePlan: string;
  products: Array<any> = [];
  product: any;
  selectedProducts: Array<any> = [];
  selectedCountries: Array<string> = [];
  selectedProductsResult: Array<any> = [];
  coverage: CoverageCategoryOws;

  statusPlan = [
    { label: 'TRANSLATE.PRODUCT', value: 'Produto' },
    { label: 'TRANSLATE.COVERAGE', value: 'Cobertura' },
    { label: 'TRANSLATE.PRICE', value: 'Preço' },
    { label: 'TRANSLATE.ELIGIBILITY', value: 'Elegibilidade' }
  ];

  listCountry = [
    { label: 'TRANSLATE.ARGENTINA', value: Countries.ARGENTINA },
    { label: 'TRANSLATE.BRAZIL', value: Countries.BRAZIL },
    { label: 'TRANSLATE.COLOMBIA', value: Countries.COLOMBIA },
    { label: 'TRANSLATE.CHILE', value: Countries.CHILE }
  ];

  countriesFilter = [
    { label: 'Argentina', value: Countries.ARGENTINA },
    { label: 'Brasil', value: Countries.BRAZIL },
    { label: 'Chile', value: Countries.COLOMBIA },
    { label: 'Colombia', value: Countries.CHILE }
  ];

  constructor(
    private apsPermissionService: ApsPermissionService,
    public translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private productService: ProductService,
    private coverageService: CoverageService,
    private productPolicyService: ProductPolicyService,
    private priceService: PriceService,
  ) {
  }

  ngOnInit() {
    this.getMenu();
    this.selectTypePlan = 'Produto';
    this.getPrice();
  }

  async getMenu() {

    // renderiza os itens de menu, na primeira vez que acessar a tela
    this.navMenuItemsArray();

    // renderiza ao fazer a troca de linguagem
    this.translateService.onLangChange.subscribe(
      async (translate: LangChangeEvent) => (
        this.navMenuItemsArray()
      ));
  }

  async navMenuItemsArray() {

    this.bottonItems = [
      {
        label: this.translateService.instant('TRANSLATE.ADD'),
        icon: 'fa fa-plus',
      },
      {
        label: this.translateService.instant('TRANSLATE.PRODUCT'),
        routerLink: '/new-product'
      },
      {
        label: this.translateService.instant('TRANSLATE.COVERAGE'),
        routerLink: '/new-coverage'
      },
      {
        label: this.translateService.instant('TRANSLATE.PRICE'),
        routerLink: '/new-price'
      },
      {
        label: this.translateService.instant('TRANSLATE.ELIGIBILITY'),
        routerLink: '/new-eligibility'
      }
    ];
  }

  changeLang(plan: string) {

    if (plan === 'Produto' || plan === 'Producto') {
      this.router.navigate(['new-product'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === 'Cobertura' || plan === 'Portada') {
      this.router.navigate(['new-coverage'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === 'Preço' || plan === 'Precio') {
      this.router.navigate(['new-price'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === 'Elegibilidade' || plan === 'Elegibilidad') {
      this.router.navigate(['new-eligibility'], { skipLocationChange: false, replaceUrl: false });

    } else {
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'URL, não encontrada.' });
    }

  }

  generalSearch() {
    this.cleanFilters();
    this.getPrice();
  }

  getPrice() {

    if (this.selectTypePlan === 'Preço') {

      if (this.selectedProducts.length > 0) {

        this.selectedProductsResult = this.selectedProducts;
      } else {

        this.priceService.getAllPrices()
          .subscribe((response: Array<PriceDto>) => {

            this.products = response;

          }, (err) => {
            if (err.status == 400) {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('TRANSLATE.ERROR'),
                detail: this.translateService.instant('TRANSLATE.PRICE.NOT.FOUND')
              });
            }
          });

      }
    }
  }

  countryChange(event: any) {
    this.selectedCountries = event.value;
  }

  productChange(event: any) {
    this.selectedProducts = event.value;
  }

  validateSearch() {

    if (this.selectTypePlan === 'Preço') {

      if (this.selectedProducts.length > 0) {

        this.selectedProductsResult = this.selectedProducts;
      } else {

        this.getPrice();
        this.selectedProductsResult = this.products;

      }

    } else if (this.selectedCountries.length > 0) {

      if (this.selectedProducts.length > 0) {

        this.selectedProductsResult = this.selectedProducts;
      }
      else {

        switch (this.selectTypePlan) {
          case 'Produto':
            this.productService.getProductsByCountry(this.selectedCountries)
              .subscribe((response: Array<ProductVisit>) => {

                this.products = response;
                this.selectedProductsResult = this.products;
              }, (err) => {
                if (err.status == 400) {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
                  });
                }
              });
            break;

          case "Cobertura":
            this.coverageService.getCoveragesByCountry(this.selectedCountries)
              .subscribe((response: Array<CoverageCategoryOws>) => {

                this.products = response;
                this.selectedProductsResult = this.products;
              }, (err) => {
                if (err.status == 400) {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.COVERAGE.NOT.FOUND')
                  });
                }
              })
            break;

          case 'Elegibilidade':
            this.productPolicyService.getAllProductPoliciesByCountry(this.selectedCountries)
              .subscribe((response: Array<ProductPolicy>) => {

                this.products = response;
                this.selectedProductsResult = this.products;
              }, (err) => {

                if (err.status == 400) {
                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.ELEGIBILITY.NOT.FOUND')
                  });
                }
              })
            break;
        }

      }

    } else {

      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.ERROR.SELECT.COUNTRY')
      });
    }

  }

  cleanFilters() {
    this.products = [];
    this.selectedCountries = [];
    this.selectedProductsResult = [];
    this.selectedProducts = [];
    this.countriesFilter = this.countriesFilter;
    this.product = "";
    this.getPrice();
  }

}
