import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { ClientType } from 'src/app/models/enum/client-type';
import { PriceName } from 'src/app/models/enum/price-name';
import { CoverageCategory } from 'src/app/models/product/coverage-category';
import { PriceDto } from 'src/app/models/product/price-dto';
import { ProductPolicyRequestDto } from "src/app/models/product/product-policy-request";
import { ProductPolicyResponseDto } from "src/app/models/product/product-policy-response";
import { ProductVisit } from 'src/app/models/product/product-visit';
import { VinCategory } from 'src/app/models/vin/vin-category';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';
import { PriceService } from 'src/app/services/price/price.service';
import { ProductPolicyService } from 'src/app/services/product-policy/product-policy.service';
import { ProductService } from 'src/app/services/product/product.service';
import { VinService } from 'src/app/services/vin/vin.service';

@Component({
  selector: 'new-eligibility',
  templateUrl: './new-eligibility.component.html',
  styleUrls: ['./new-eligibility.component.scss']
})
export class NewEligibilityComponent implements OnInit {

  listCountries = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.ARGENTINA', value: ConstantsFordProtect.ARG },
    { label: 'TRANSLATE.BRAZIL', value: ConstantsFordProtect.BRA },
    { label: 'TRANSLATE.CHILE', value: ConstantsFordProtect.CHL },
    { label: 'TRANSLATE.COLOMBIA', value: ConstantsFordProtect.COL }
  ];

  listSalesChannel = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.DEALER', value: ConstantsFordProtect.DEALER },
    { label: 'TRANSLATE.TELEMARKETING', value: ConstantsFordProtect.TELEMARKETING }
  ];

  listStatus = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.ACTIVE', value: ConstantsFordProtect.COVERAGE_ACTIVE },
    { label: 'TRANSLATE.INACTIVE', value: ConstantsFordProtect.COVERAGE_INACTIVE }
  ];

  listYearVehicle = [
    { label: 'TRANSLATE.SELECT' },
    { label: 2021, value: 2021 },
    { label: 2022, value: 2022 },
    { label: 2023, value: 2023 },
    { label: 2024, value: 2024 },
    { label: 2025, value: 2025 },
    { label: 2026, value: 2026 },
    { label: 2027, value: 2027 }
  ];

  userform: FormGroup;
  selectedProducts: Array<any> = [];
  products: Array<any> = [];
  idProduct: number;
  placeholderProduct: string = 'TRANSLATE.SELECT';

  selectedCountry: string;
  country: string;

  selectedCoverages: Array<any> = [];
  coverages: Array<any> = [];
  coveragesConcat: Array<any> = [];
  idCoverage: number;
  placeholderCoverage: string = 'TRANSLATE.SELECT';

  selectCanalVenda: string;
  selectedStatus: number;
  selectedYearVehicle: number;
  year: number;

  selectedVehicleLine: string;
  vehicleLines: Array<any> = [];
  vehicleLineConcat: Array<any> = [];
  idVehicle: number;
  placeholderVehicle: string = 'TRANSLATE.SELECT';

  selectedCustomerPrice: number;
  customersPrices: Array<any> = [];
  customersPricesConcat: Array<any> = [];
  placeholdercustomerPrices: string = 'TRANSLATE.SELECT';
  priceName: string = PriceName.PRICE_CUSTOMER;
  idPriceName: number;
  inputMonths: number;
  productPolicyRequestDto: ProductPolicyRequestDto;
	productPolicyResponseDto: ProductPolicyResponseDto;
  productArray: Array<any> = [];

  constructor(
    private fb: FormBuilder,
    private apsPermissionService: ApsPermissionService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private productService: ProductService,
    private coverageService: CoverageService,
    private vinService: VinService,
    private priceService: PriceService,
    private productPolicyService: ProductPolicyService,
    private router: Router
  ) { }

  ngOnInit() {

    this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenViewsPlans);
    this.userform = this.fb.group({
      'selectCountry': [null, Validators.required],
      'selectProduct': [null, Validators.required],
      'selectCoverage': [null, Validators.required],
      'selectCanalVendas': [null, Validators.required],
      'selectStatus': [null, Validators.required],
      'selectYearVehicles': [null, Validators.required],
      'selectVehicleLines': [null, Validators.required],
      'inputMonthsOffer': ['', [Validators.required, Validators.min(1), Validators.max(999), Validators.pattern('[0-9]*')]],
      'selectedCustomerPrices': [null, Validators.required],
      'inputEligibilityName': [''],
      'inputDescriptionEligibility': ['']
    });
    this.getByCustomersPrices();
    this.productPolicyRequestDto = new ProductPolicyRequestDto();
		this.productPolicyResponseDto = new ProductPolicyResponseDto();
  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  countryChange(event: any) {
    this.selectedCountry = event.value;
    this.country = this.selectedCountry;
    this.getProducts(this.selectedCountry);
  }

  getProducts(country: string) {

    this.productService
      .getProductsByCountryForProductPolicies(country)
      .subscribe((response: Array<ProductVisit>) => {

        this.products = response;

        this.productArray = this.products.map(object => {
          return {
            id: object.productId,
            name: object.productName
          }
        });

        if (this.products.length > 0) {
          this.placeholderProduct = 'TRANSLATE.SELECT';
        } else {
          this.placeholderProduct = 'TRANSLATE.NO.RESULT.FOUND';
        }

      }, (err) => {
        if (err.status == 400) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
          });
        }
      });

  }

  productChange(event: any) {
    this.selectedProducts = event.value;
    const id = JSON.stringify(this.selectedProducts);
    this.idProduct = JSON.parse(id).id;

    this.getSearchByProductId(this.idProduct);
  }

  getSearchByProductId(idProduct: number) {

    this.coverageService
      .getSearchByProductId(idProduct)
      .subscribe((response: Array<CoverageCategory>) => {

        this.coverages = response;

        this.coveragesConcat = this.coverages.map(object => {
          return {
            label: `${object.visit} Visitas - ${object.term} Meses ${object.distance !== null ? ' - ' + object.distance + ' Km.' : '.'}`,
            id: object.id
          }
        });

        if (this.coveragesConcat.length > 0) {
          this.placeholderCoverage = 'TRANSLATE.SELECT';
        } else {
          this.placeholderCoverage = 'TRANSLATE.NO.RESULT.FOUND';
        }

      }, (err) => {
        if (err.status == 400) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.COVERAGE.NOT.FOUND')
          });
        }
      });

  }

  coverageChange(event: any) {
    this.selectedCoverages = event.value;
    const id = JSON.stringify(this.selectedCoverages);
    this.idCoverage = JSON.parse(id).id;
  }

  canalVendasChange(event: any) {
    this.selectCanalVenda = event.value;
  }

  statusChange(event: any) {
    this.selectedStatus = event.value;
  }

  yearVehicleChange(event: any) {
    this.selectedYearVehicle = event.value;
    this.year = this.selectedYearVehicle;
    this.getByCountryAndYear(this.country, this.year)
  }

  getByCountryAndYear(country: string, year: number) {
    this.vinService
      .getByCountryAndYear(country, year)
      .subscribe((response: Array<VinCategory>) => {

        this.vehicleLines = response;

        this.vehicleLineConcat = this.vehicleLines.map(object => {
          return {
            label: `${object.vehicleLine} - ${object.vehicleLineCode} - ${object.tmaCode}${object.versionCode !== null ? ' - ' + object.versionCode + '.' : '.'}`,
            id: object.idVinCategoryVersion
          }
        });

        if (this.vehicleLineConcat.length > 0) {
          this.placeholderVehicle = 'TRANSLATE.SELECT';
        } else {
          this.placeholderVehicle = 'TRANSLATE.NO.RESULT.FOUND';
        }

      }, (err) => {
        if (err.status == 400) {

          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.COVERAGE.NOT.FOUND')
          });
        }
      });
  }

  vehicleLineChange(event: any) {
    this.selectedVehicleLine = event.value;
    const id = JSON.stringify(this.selectedVehicleLine);
    this.idVehicle = JSON.parse(id).id;
  }

  getByCustomersPrices() {

    this.priceService.getByCustomersPrices(this.priceName)
      .subscribe((response: Array<PriceDto>) => {

        this.customersPrices = response;

        this.customersPricesConcat = this.customersPrices.map(object => {

          let formattedPrice;

          if (object.itemPriceUnit === 'R$') {
            formattedPrice = object.itemPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

          } else if (object.itemPriceUnit === '$') {
            formattedPrice = object.itemPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

          }
          return {
            label: `${formattedPrice}`,
            id: object.id
          }
        });

        if (this.customersPricesConcat.length > 0) {
          this.placeholdercustomerPrices = 'TRANSLATE.SELECT';
        } else {
          this.placeholdercustomerPrices = 'TRANSLATE.NO.RESULT.FOUND';
        }

      }, (err) => {
        if (err.status == 400) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.PRICE.NOT.FOUND')
          });
        }
      });
  }

  customerPriceChange(event: any) {
    this.selectedCustomerPrice = event.value;
    const idPrices = JSON.stringify(this.selectedCustomerPrice);
    this.idPriceName = JSON.parse(idPrices).id;
  }

  formatNumber(event: any) {
    const input = event.target;
    let value = input.value;
    const valueFormat = value.replace(/[^0-9]/g, '');

    input.value = valueFormat;
    this.inputMonths = valueFormat;
  }

  save() {

    if (this.selectedCountry === undefined) {
      this.userform.get('selectCountry').markAsTouched();
      this.selectedCountry = null;
    }

    if (this.products.length > 0 && this.selectedProducts.length <= 0) {
      this.userform.get('selectProduct').markAsTouched();
      this.selectedProducts = null;
    }

    if (this.coveragesConcat.length > 0 && this.selectedCoverages.length <= 0) {
      this.userform.get('selectCoverage').markAsTouched();
      this.selectedCoverages = null;
    }

    if (this.selectCanalVenda === null || this.selectCanalVenda === undefined) {
      this.userform.get('selectCanalVendas').markAsTouched();
      this.selectCanalVenda = null;
    }

    if (this.selectedStatus === undefined || this.selectedStatus === null) {
      this.userform.get('selectStatus').markAsTouched();
      this.selectedStatus = null;
    }

    if (this.selectedYearVehicle === undefined || this.selectedYearVehicle === null) {
      this.userform.get('selectYearVehicles').markAsTouched();
      this.selectedYearVehicle = null;
    }

    if (this.selectedYearVehicle !== null && this.selectedVehicleLine === undefined || this.selectedVehicleLine === null) {
      this.userform.get('selectVehicleLines').markAsTouched();
      this.selectedVehicleLine = null;
    }

    if (this.userform.get('inputMonthsOffer').invalid) {
      this.userform.get('inputMonthsOffer').markAsPending();
    }
    
    if (this.selectedCustomerPrice === undefined || this.selectedCustomerPrice === null) {
      this.userform.get('selectedCustomerPrices').markAsTouched();
      this.selectedCustomerPrice = null;
    }

    if (this.userform.valid) {

      this.productPolicyRequestDto.country = this.country;
      this.productPolicyRequestDto.productId = this.idProduct;
      this.productPolicyRequestDto.coverageCategoryId = this.idCoverage;
      this.productPolicyRequestDto.saleType = parseInt(this.selectCanalVenda, 10);
      this.productPolicyRequestDto.isActive = this.selectedStatus;
      this.productPolicyRequestDto.vinCategoryVersionId = this.idVehicle;
      this.productPolicyRequestDto.user = sessionStorage.getItem('userId');
      this.productPolicyRequestDto.months = this.inputMonths;
      this.productPolicyRequestDto.clientType = ClientType.PF_PJ;
      this.productPolicyRequestDto.itemPriceId = this.idPriceName;

      this.productPolicyService
        .save(this.productPolicyRequestDto)
        .subscribe((response: ProductPolicyResponseDto) => {

          this.productPolicyResponseDto = response;

        }, (err) => {

          if (err.status == 400 || err.status == 404) {

            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('TRANSLATE.ERROR'),
              detail: this.translateService.instant('TRANSLATE.NEW.ELIGIBILITY.ERROR')
            });
          }
        });

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('TRANSLATE.SUCCESS'),
        detail: this.translateService.instant('TRANSLATE.SUCCESS.CREATE')
      });

      this.buttonReturn();

    } else {

      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
      });
    }

  }

}