import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ChassiValidation } from "src/app/models/chassi-validation/chassi-validation";
import { ChoosePlanBuyng } from 'src/app/models/choose-plan/choose-plan-buyng';
import { ClientData } from 'src/app/models/client-data/client-data';
import { ContractSummary } from 'src/app/models/contract-summary/contract-summary';
import { ContractPendingSignature } from 'src/app/models/contract/contract-pending-signature';
import { Dealer } from 'src/app/models/dealer/dealer';
import { DealerApsFordDetailAttributes } from 'src/app/models/dealer/dealer-aps-ford-detail-attributes';
import { DealerSeller } from 'src/app/models/dealer/dealer-seller';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { ProductFull } from 'src/app/models/product/product-full';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ChoosePlanService } from 'src/app/services/choose-plan/choose-plan.service';
import { ClientDataService } from 'src/app/services/client-data/client-data.service';
import { ContractSummaryService } from 'src/app/services/contract-summary/contract-summary.service';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';
import { SystemCountryService } from 'src/app/services/system-country/system-country.service';
import { VehicleInformationService } from 'src/app/services/vehicle-information/vehicle-information.service';

@Component({
	selector: 'app-customer',
	templateUrl: './contract-summary.component.html',
	styleUrls: ['./contract-summary.component.scss']
})
export class ContractSummaryComponent implements OnInit {

	userform: FormGroup;
	chassiValidation: ChassiValidation;
	clientData: ClientData;
	contractSummary: ContractSummary;
	choosePlanBuyng: ChoosePlanBuyng;
	VISTA: string = 'VISTA';
	PARCELADO: string = 'PARCELADO';
	disableFormaDropdown: boolean;
	cdsid: string = sessionStorage.getItem('userId');
	saleChannel: string;
	nextStepText: string;

	dropdownTypePayment: any[] = [
		{ label: 'TRANSLATE.SELECT', value: null },
		{ label: 'TRANSLATE.YES', value: true },
		{ label: 'TRANSLATE.NOT', value: false }
	];

	dropdownFormaPayment: any[] = [
		{ label: 'TRANSLATE.SELECT', value: null },
		{ label: 'TRANSLATE.IN.CASH', value: this.VISTA },
		{ label: 'TRANSLATE.INSTALLMENTS', value: this.PARCELADO }
	];

	dropdownPrazoPayment: any[] = [
		{ label: 'TRANSLATE.SELECT', value: null }
	];
	isSubmitted: boolean = false;
	blockedUi: boolean = false;
	contractIdDownload: number = 0;
	isContractCreated: boolean = false;
	lang: string;
	doubleZero: string = '00';

	constructor(private contractSummaryService: ContractSummaryService,
		private vehicleInformationService: VehicleInformationService,
		private clientDataService: ClientDataService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private choosePlanService: ChoosePlanService,
		private apsPermissionService: ApsPermissionService,
		private dealerService: DealerService,
		private messageExceptionService: MessageExceptionService,
		private systemCountryService: SystemCountryService
	) {

		this.contractSummary = new ContractSummary();
		this.choosePlanBuyng = new ChoosePlanBuyng();
		this.saleChannel = route.snapshot.paramMap.get('saleChannel');
		this.systemCountryService.setSystemCountry();
	}

	ngOnInit() {
		if (this.systemCountryService.getSystemCountry() == "BRA") {
			this.nextStepText = "TRANSLATE.ADVANCE";
		} else {
			this.nextStepText = "TRANSLATE.FINALIZE";
		}

		this.chassiValidation = this.vehicleInformationService.getAllChassiValidation();
		this.clientData = this.clientDataService.getClientData();

		if (this.chassiValidation == null || this.chassiValidation.validated == StatusChassi.invalid ||
			this.clientDataService.getClientData() === null || this.clientData === null ||
			this.choosePlanService.getChoosePlanBuyng() == null) {
			this.router.navigate(['sales-channel', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
		}

		this.getSellerAutomatic();
		this.getDealerAutomatic();
		/*      
				COMENTADO NO DIA 14/11/2023 em carater de urgência. O Dealer não estava conseguindo finalizar o contrato.
		
				switch (this.systemCountryService.getSystemCountry()) {
		
					case "COL":				
		
						(<HTMLInputElement>document.getElementById("phoneSeller")).maxLength = 12; 
						(<HTMLInputElement>document.getElementById("phoneSeller")).minLength = 7;
						this.userform = this.formControlGenerator(7, 12); 
					break;
		
					case "CHL":				
						(<HTMLInputElement>document.getElementById("phoneSeller")).maxLength = 11;
						(<HTMLInputElement>document.getElementById("phoneSeller")).minLength = 11;
						this.userform = this.formControlGenerator(11, 11); 
					break;
		
					default:				
						(<HTMLInputElement>document.getElementById("phoneSeller")).minLength = 10;
						(<HTMLInputElement>document.getElementById("phoneSeller")).maxLength = 13;
						this.userform = this.formControlGenerator(10, 13); 
					break;
				}
		*/
		/* LINHA ADICIONADA NO DIA 14/11/2023 em carater de urgência. O Dealer não estava conseguindo finalizar o contrato. */
		this.userform = this.formControlGenerator(0, 100);

		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenContractReview);

		if (this.contractSummaryService.getContractSummary() != null) {
			this.contractSummary = this.contractSummaryService.getContractSummary();
		}

		if (this.choosePlanService.getChoosePlanBuyng() != null) {
			this.choosePlanBuyng = this.choosePlanService.getChoosePlanBuyng();
		}

		this.updateForma();
	}

	private formControlGenerator(phoneMinlength: number, phoneMaxlength: number) {
		return this.fb.group({
			'dealerCodigo': new FormControl('', Validators.required),
			'cdsidSeller': new FormControl('', Validators.required),
			'cpfSeller': new FormControl('',),
			'nameSeller': new FormControl('', Validators.required),
			// COMENTADO NO DIA 14/11/2023 em carater de urgência. O Dealer não estava conseguindo finalizar o contrato.
			//'inputPhoneSeller': new FormControl('', [Validators.required, Validators.minLength(phoneMinlength), Validators.maxLength(phoneMaxlength), Validators.pattern('[0-9]*')]),
			'inputPhoneSeller': new FormControl('', Validators.required),
			'emailSeller': new FormControl('', [Validators.required, Validators.email]),
			'dealerEmail': new FormControl('', [Validators.required, Validators.email]),
			'typePayment': new FormControl('', Validators.required),
			'formaPayment': new FormControl('', Validators.required),
			'prazoPayment': new FormControl('', Validators.required),
			'documentSeller': new FormControl('',),
		});
	}

	buttonNext() {

		if (this.systemCountryService.getSystemCountry() == "BRA") {
			this.contractSummaryService.setContractSummary(this.contractSummary);
			if (this.contractSummary.dealerSeller.document == undefined || this.contractSummary.dealerSeller.document === '') {
				if (this.contractSummary.typePayment.value == true) {
					this.router.navigate(['contract-summary-attachment-one', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
				} else if (this.contractSummary.typePayment.value == false) {
					this.router.navigate(['contract-summary-attachment-two', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
				}

			} else {
				if (this.contractSummary.typePayment.value == true) {
					this.router.navigate(['contract-summary-attachment-one', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
				} else if (this.contractSummary.typePayment.value == false) {
					this.router.navigate(['contract-summary-attachment-two', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
				}
			}
		} else {
			this.save();
		}
	}

	buttonComeBack() {
		this.dropdownTypePayment = [
			{ label: 'TRANSLATE.SELECT', value: null }
		];

		this.dropdownFormaPayment = [
			{ label: 'TRANSLATE.SELECT', value: null }
		];

		this.dropdownPrazoPayment = [
			{ label: 'TRANSLATE.SELECT', value: null }
		];
		this.router.navigate(['client-data', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
	}

	getDealer(event: KeyboardEvent) {
		this.contractSummary.dealer.geoSalesCode = 'BRA';

		this.dealerService.getDealer(this.contractSummary.dealer)
			.subscribe((response: Dealer) => {
				this.contractSummary.dealer = response;
				if (this.contractSummary.dealer == null) {
					this.messageService.add({ severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'), detail: this.translateService.instant('TRANSLATE.DEALER.NOT.FOUND') });
				}

			}, (err) => {

				if (err.status === 404) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.DEALER.NOT.FOUND')
					});
				} else if (err.status === 401) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.VEHICLE.NOT.ACCESS.LOGIN.AGAIN')
					});

				} else {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
					});
				}

			});
	}

	getDealerSeller(event: KeyboardEvent) {
		this.dealerService.getDealerSeller(this.contractSummary.dealerSeller)
			.subscribe((response: DealerSeller) => {
				this.contractSummary.dealerSeller = response;

				if (this.contractSummary.dealerSeller == null) {
					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.SELLER.NOT.FOUND')
					});
				}
			}, (err) => {

				if (err.status === 404) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.SELLER.NOT.FOUND')
					});
				} else if (err.status === 401) {

					this.messageExceptionService
						.getMessageException(err.status,
							this.translateService.instant('TRANSLATE.VEHICLE.NOT.ACCESS.LOGIN.AGAIN'));
				} else {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
					});
				}

			});
	}

	getSellerAutomatic() {
		this.contractSummary.dealerSeller.sellerCode = this.cdsid;
		this.dealerService.getDealerSeller(this.contractSummary.dealerSeller)
			.subscribe((response: DealerSeller) => {
				this.contractSummary.dealerSeller = response;
				this.contractSummary.dealerSeller.id = response.id;
				this.contractSummary.dealerSeller.sellerCode = response.sellerCode;
				this.contractSummary.dealerSeller.name = response.name;
				this.contractSummary.dealerSeller.phone = response.phone;
				this.contractSummary.dealerSeller.email = response.email;
				this.contractSummary.dealerSeller.document = response.document;

				if (response.birthday != null) {
					this.contractSummary.dealerSeller.birthday = new Date(response.birthday);
				}

			}, (err) => {

				if (err.status === 404) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.SELLER.NOT.FOUND')
					});
				} else if (err.status === 401) {

					this.messageExceptionService
						.getMessageException(err.status,
							this.translateService.instant('TRANSLATE.VEHICLE.NOT.ACCESS.LOGIN.AGAIN'));
				} else {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
					});
				}
				
			});

	}

	/*
	* captura o cdsid que está na sessão;
	* informa ele no serviço de DLS;
	* se houver um response, verifica se tem um sitecode e atribui;
	* se não houver response, atribui o site code 00100 (FORD MOTOR COMPANY BRASIL LTDA) automaticamente;
	*/
	getDealerAutomatic() {
		this.contractSummary.dealerSeller.name = this.cdsid;
		this.dealerService.getDealerName(this.contractSummary.dealerSeller).subscribe((response: DealerApsFordDetailAttributes) => {
			if (response == null) {
				this.contractSummary.dealer.pandAC = "00100";
			} else {
				this.contractSummary.dealer.pandAC = response.attributeValue;
			}
			this.contractSummary.dealer.geoSalesCode = 'BRA';
			this.dealerService.getDealer(this.contractSummary.dealer)
				.subscribe((response: Dealer) => {
					this.contractSummary.dealer = response;
				});
		});
	}

	updateForma() {
		this.dropdownPrazoPaymentSelecione();
		if (this.contractSummary.formaPayment != null) {
			if (this.contractSummary.formaPayment.value === this.VISTA) {
				this.dropdownPrazoPaymentOneMonth();
			} else {
				this.dropdownPrazoPaymentSomeMonths();
			}
		}
	}

	dropdownPrazoPaymentSomeMonths() {
		this.dropdownPrazoPayment = [
			{ label: 'TRANSLATE.SELECT', value: null },
			{ label: 'TRANSLATE.MONTH.ONE', value: 1 },
			{ label: 'TRANSLATE.MONTH.TWO', value: 2 },
			{ label: 'TRANSLATE.MONTH.THREE', value: 3 },
			{ label: 'TRANSLATE.MONTH.FOUR', value: 4 },
			{ label: 'TRANSLATE.MONTH.FIVE', value: 5 },
			{ label: 'TRANSLATE.MONTH.SIX', value: 6 },
			{ label: 'TRANSLATE.MONTH.SEVEN', value: 7 },
			{ label: 'TRANSLATE.MONTH.EIGHT', value: 8 },
			{ label: 'TRANSLATE.MONTH.NINE', value: 9 },
			{ label: 'TRANSLATE.MONTH.TEN', value: 10 },
			{ label: 'TRANSLATE.MONTH.ELEVEN', value: 11 },
			{ label: 'TRANSLATE.MONTH.TWELVE', value: 12 },
			{ label: 'TRANSLATE.MONTH.EIGHTEEN', value: 18 },
			{ label: 'TRANSLATE.MONTH.TWENTY.FOUR', value: 24 },
			{ label: 'TRANSLATE.MONTH.THIRTY.SIX', value: 36 },
			{ label: 'TRANSLATE.MONTH.FORTY.EIGHT', value: 48 },
			{ label: 'TRANSLATE.MONTH.SIXTY', value: 60 }
		];
	}

	dropdownPrazoPaymentOneMonth() {
		this.dropdownPrazoPayment = [
			{ label: 'TRANSLATE.SELECT', value: null },
			{ label: 'TRANSLATE.NOT.APPLICABLE', value: 1 }
		];
	}

	dropdownPrazoPaymentSelecione() {
		this.dropdownPrazoPayment = [
			{ label: 'TRANSLATE.SELECT', value: null }
		];
	}

	pt = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
		dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
		dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
		monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
			'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
		monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		today: 'Hoje',
		clear: 'Limpar'
	};

	es = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
		dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
		dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
		monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
			'Agosto', 'Septiembre', 'Octubre', 'Noviembro', 'Diciembro'],
		monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		today: 'Hoy',
		clear: 'Limpar'
	};

	rangeDateLabel(): string {
		let date: Date = new Date();
		let dateLabelYear: number = date.getFullYear();
		return '1900:' + dateLabelYear;
	}

	isValidCPF(value: string): boolean {
		if (typeof value !== 'string') {
			return false;
		}

		value = value.replace(/[^\d]+/g, '');

		if (value.length !== 11 || !!value.match(/(\d)\1{10}/)) {
			return false;
		}

		const values = value.split('').map(el => +el);
		const rest = (count) => (values.slice(0, count - 12).reduce((soma, el, index) => (soma + el * (count - index)), 0) * 10) % 11 % 10;

		return rest(10) === values[9] && rest(11) === values[10];
	}

	contractStartDate(): Date {
		let date = new Date();
		if (this.chassiValidation.warrantyStartDate != null) {
			date = this.chassiValidation.warrantyStartDate;
		}
		return date;
	}


	nationalId(cpfCnpj: string): string {
		if (cpfCnpj.length == 11) {
			return 'PF';
		} else {
			return 'PJ';
		}
	}

	save(): void {

		this.isSubmitted = true;
		this.blockedUi = true;

		this.contractSummaryService.saveContractPendingSignature(this.setContractPendingSignature())
			.subscribe((response: ContractPendingSignature) => {
				this.messageService.add({
					severity: 'success',
					summary: this.translateService.instant('TRANSLATE.SUCCESS'),
					detail: this.translateService.instant('TRANSLATE.CONTRACT.CREATED.SUCCESSFULL')
				});
				this.chassiValidation = new ChassiValidation();
				this.choosePlanBuyng = new ChoosePlanBuyng();
				this.clientData = new ClientData();
				this.contractSummary = new ContractSummary();

				this.contractIdDownload = response.contractId;
				this.isContractCreated = true;
				this.blockedUi = false;
				this.lang = "";
				this.router.navigate(['home']);
			}, (err) => {
				this.messageExceptionService.getMessageException(err.status,
					this.translateService.instant('TRANSLATE.CONTRACT.COULD.NOT.SAVED.CLOSE.PAGE.LOG.AGAIN'));

				this.isSubmitted = false;
				this.blockedUi = false;
				this.isContractCreated = false;
			});
	}


	private setContractPendingSignature(): ContractPendingSignature {
		let contractPendingSignature = new ContractPendingSignature();
		contractPendingSignature.country = this.systemCountryService.getSystemCountry();
		contractPendingSignature.operation = "CAR";
		contractPendingSignature.status = 60;
		contractPendingSignature.user = "EMISSAO";
		contractPendingSignature.saleDate = new Date();
		contractPendingSignature.startDate = this.contractStartDate();
		contractPendingSignature.saleType = 5;
		contractPendingSignature.finalPrice = this.choosePlanBuyng.totalPrice;

		if (this.chassiValidation.odometro != null) {
			contractPendingSignature.odometerStart = this.chassiValidation.odometro;
		} else {
			contractPendingSignature.odometerStart = 1;
		}

		contractPendingSignature.vin.vin = this.chassiValidation.vin;
		contractPendingSignature.vin.cvvgDate = this.contractStartDate();
		contractPendingSignature.vin.factoryWarrantyEndDate = null;
		contractPendingSignature.vin.idVinCategoryVersion = this.chassiValidation.idVinCategoryVersion;

		contractPendingSignature.payments.method = "DEALER";
		contractPendingSignature.payments.modality = this.contractSummary.formaPayment.value;
		contractPendingSignature.payments.parcels = this.contractSummary.prazoPayment.value;
		contractPendingSignature.payments.totalValue = this.choosePlanBuyng.totalPrice

		contractPendingSignature.dealers.code = this.contractSummary.dealer.dealerCode;
		contractPendingSignature.dealers.pAndAC = this.contractSummary.dealer.pandAC;
		contractPendingSignature.dealers.divisionCode = this.contractSummary.dealer.divisionCode;
		contractPendingSignature.dealers.oldRegionCode = this.contractSummary.dealer.oldRegionCode;
		contractPendingSignature.dealers.geoSalesCode = this.contractSummary.dealer.geoSalesCode;
		contractPendingSignature.dealers.subCode = this.contractSummary.dealer.dealerSubCode;
		contractPendingSignature.dealers.name = this.contractSummary.dealer.dealerName;
		contractPendingSignature.dealers.email = this.contractSummary.dealer.dealerEmail;
		contractPendingSignature.dealers.phone = this.contractSummary.dealer.dealerPhone;

		contractPendingSignature.sellers.code = this.contractSummary.dealerSeller.sellerCode;
		contractPendingSignature.sellers.name = this.contractSummary.dealerSeller.name;
		contractPendingSignature.sellers.phone = this.contractSummary.dealerSeller.phone;
		contractPendingSignature.sellers.email = this.contractSummary.dealerSeller.email;
		contractPendingSignature.sellers.document = this.contractSummary.dealerSeller.document;
		contractPendingSignature.sellers.birthday = this.contractSummary.dealerSeller.birthday;

		contractPendingSignature.customers.country = this.clientData.countryCode;
		contractPendingSignature.customers.documentType = this.nationalId(this.clientData.nationalId);
		contractPendingSignature.customers.documentNumber = this.clientData.nationalId;
		contractPendingSignature.customers.name = this.clientData.firstname;
		contractPendingSignature.customers.phone = this.clientData.phone1;
		contractPendingSignature.customers.email = this.clientData.email1;
		contractPendingSignature.customers.lastName = this.clientData.lastname;
		contractPendingSignature.customers.customerSocialName = this.clientData.customerSocialName;

		if (this.systemCountryService.getSystemCountry() !== 'BRA' &&
			(this.clientData.zip == undefined || this.clientData.zip === '')) {

			this.clientData.zip = this.doubleZero;
		}

		contractPendingSignature.customers.cep = this.clientData.zip;
		contractPendingSignature.customers.streetName = this.clientData.address;
		contractPendingSignature.customers.streetNumber = this.clientData.number;
		contractPendingSignature.customers.streetComplement = this.clientData.complement;
		contractPendingSignature.customers.district = this.clientData.district;
		contractPendingSignature.customers.state = this.clientData.stateCode;
		contractPendingSignature.customers.city = this.clientData.city;

		if (this.choosePlanBuyng.extension.coverageCategoryId > 0 && this.choosePlanBuyng.extension.productId > 0) {
			let productFullExtension = new ProductFull();
			productFullExtension.coverageId = this.choosePlanBuyng.extension.coverageCategoryId;
			productFullExtension.productId = this.choosePlanBuyng.extension.productId;
			productFullExtension.productPolicyId = this.choosePlanBuyng.extension.productPolicyKey;
			productFullExtension.priceBookId = this.choosePlanBuyng.extension.pricebookKey;
			productFullExtension.priceBookId = this.choosePlanBuyng.extension.pricebookKey;
			contractPendingSignature.products.push(productFullExtension);
		}

		if (this.choosePlanBuyng.maintenance.coverageCategoryId > 0 && this.choosePlanBuyng.maintenance.productId > 0) {
			let productFullMaintenance = new ProductFull();
			productFullMaintenance.coverageId = this.choosePlanBuyng.maintenance.coverageCategoryId;
			productFullMaintenance.productId = this.choosePlanBuyng.maintenance.productId;
			productFullMaintenance.productPolicyId = this.choosePlanBuyng.extension.productPolicyKey;
			productFullMaintenance.priceBookId = this.choosePlanBuyng.extension.pricebookKey;
			productFullMaintenance.productPolicyId = this.choosePlanBuyng.maintenance.productPolicyKey;
			productFullMaintenance.priceBookId = this.choosePlanBuyng.maintenance.pricebookKey;
			contractPendingSignature.products.push(productFullMaintenance);
		}

		if (this.choosePlanBuyng.part.coverageCategoryId > 0 && this.choosePlanBuyng.part.productId > 0) {
			let productFullPart = new ProductFull();
			productFullPart.coverageId = this.choosePlanBuyng.part.coverageCategoryId;
			productFullPart.productId = this.choosePlanBuyng.part.productId;
			productFullPart.productPolicyId = this.choosePlanBuyng.extension.productPolicyKey;
			productFullPart.priceBookId = this.choosePlanBuyng.extension.pricebookKey;
			productFullPart.productPolicyId = this.choosePlanBuyng.part.productPolicyKey;
			productFullPart.priceBookId = this.choosePlanBuyng.part.pricebookKey;
			contractPendingSignature.products.push(productFullPart);
		}

		return contractPendingSignature;
	}

}
