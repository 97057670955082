import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad';
import html2pdf from 'html2pdf.js';
import { MessageService } from 'primeng';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { ChoosePlanBuyng } from 'src/app/models/choose-plan/choose-plan-buyng';
import { ClientData } from 'src/app/models/client-data/client-data';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { ContractSummary } from 'src/app/models/contract-summary/contract-summary';
import { ContractSignature } from 'src/app/models/contract/contract-signature';
import { CreateContractSignature } from 'src/app/models/contract/create-contract-signature';
import { DownloadFile } from 'src/app/models/contract/download-file';
import { Dealer } from 'src/app/models/dealer/dealer';
import { DealerSeller } from 'src/app/models/dealer/dealer-seller';
import { FileFull } from 'src/app/models/files/file-full';
import { ContractService } from 'src/app/services/contract/contract.service';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';

@Component({
  selector: 'contract-signature',
  templateUrl: './contract-signature.component.html',
  styleUrls: ['./contract-signature.component.scss']
})
export class ContractSignatureComponent implements OnInit {

  contractIdDownload: number = 0;
  isContractCreated: boolean = false;
  isContractActive: boolean = false;
  invalidSignature: boolean = true;
  submitted: boolean = false;
  termsUser: boolean = false;
  isSubmitted: boolean = false;
  blockedUi: boolean = false;
  dateAttachment: Date = new Date();
  signatureImgBase64: string;
  productCode: string;
  contract: number;
  document: string;
  country: string;
  sellerCode: number;
  pandAC: string;
  uuid: string;

  clientData: ClientData;
  contractSummary: ContractSummary;
  choosePlanBuyng: ChoosePlanBuyng;
  chassiValidation: ChassiValidation;
  contractSignatureForm: FormGroup;
  contractSignature: ContractSignature;
  createContractSignature: CreateContractSignature;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  constructor(
    private messageExceptionService: MessageExceptionService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private fb: FormBuilder,
    private dealerService: DealerService,
    private translateService: TranslateService

  ) {

    this.choosePlanBuyng = new ChoosePlanBuyng;
    this.contractSummary = new ContractSummary();
    this.contractSignature = new ContractSignature;
  }

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'canvasWidth': 600,
    'canvasHeight': 100,
  };

  ngAfterViewInit() {    
  }

  drawComplete() {
    this.invalidSignature = this.validateSignature();
  }

  drawStart() {
  }

  drawClean() {
    this.signaturePad.clear();
    this.invalidSignature = this.validateSignature();
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.uuid = params.uuid;
      this.contractSummarySearch(this.uuid);
    });

    this.contractSignatureForm = this.fb.group({
      termsUser: [false, Validators.requiredTrue]
    });

  }

  contractSummarySearch(uuid: string) {
    setTimeout(()=> {

      this.contractService.getContractSignature(uuid).subscribe((response: ContractSignature) => {
          this.contractSignature = response;
          this.country = this.contractSignature.country;
          this.sellerCode = this.contractSignature.sellerK;
          this.pandAC = this.contractSignature.dealerPAndAc;

          if (this.country == 'BRA') { // taking contract only BRA type PREB

            this.getSellerAutomatic(this.sellerCode);
            this.getDealerAutomatic(this.country, this.pandAC);
  

            this.productCode = this.contractSignature.codeProduct;
            if (this.productCode == 'PREB' || this.productCode != 'PREB') {

              this.productCode;
            }
            else {
              this.productCode = this.contractSignature.extension.codeProduct;
            }

          } else {
            this.productCode = this.contractSignature.codeProduct;
          }

          if(this.contractSignature.statusContract == ConstantsFordProtect.CONTRACT_ATIVO){
            this.isContractActive = true
            this.contractIdDownload = this.contractSignature.contractId;
          }

        },
        (err) => {
						this.messageService.add({
							severity:'error', summary: 'Erro', 
							detail: this.translateService.instant('TRANSLATE.CONTRACT.NOT.FOUND')
						});
            this.contractSignature = null;
        });
      }, 1000);        
  }

  getDealerAutomatic(country: string, pandAC: string) {

    this.dealerService.getDealerSignature(country, pandAC)
      .subscribe((response: Dealer) => {
        this.contractSummary.dealer = response;
      });
  }

  getSellerAutomatic(sellerCode: number) {
    this.dealerService.getDealerSellerSignature(sellerCode)
      .subscribe((response: DealerSeller) => {
        this.contractSummary.dealerSeller = response;
        this.contractSummary.dealerSeller.id = response.id;
        this.contractSummary.dealerSeller.sellerCode = response.sellerCode;
        this.contractSummary.dealerSeller.name = response.name;
        this.contractSummary.dealerSeller.phone = response.phone;
        this.contractSummary.dealerSeller.email = response.email;
        this.contractSummary.dealerSeller.document = response.document;

        if (response.birthday != null) {
          this.contractSummary.dealerSeller.birthday = new Date(response.birthday);
        }

      });

  }

  get f() {
    return this.contractSignatureForm.controls;
  }

  getDay(): number {
    return this.dateAttachment.getDate();
  }

  getMonth(): string {
    var numberMonth = this.dateAttachment.getMonth();
    let nameMonth = '';

    switch (numberMonth) {
      case 0:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.JANUARY.UPPER.CASE');
        break;
      case 1:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.FEBRUARY.UPPER.CASE');
        break;
      case 2:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.MARCH.UPPER.CASE');
        break;
      case 3:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.APRIL.UPPER.CASE');
        break;
      case 4:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.MAY.UPPER.CASE');
        break;
      case 5:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.JUNE.UPPER.CASE');
        break;
      case 6:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.JULY.UPPER.CASE');
        break;
      case 7:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.AUGUST.UPPER.CASE');
        break;
      case 8:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.SEPTEMBER.UPPER.CASE');
        break;
      case 9:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.OCTOBER.UPPER.CASE');
        break;
      case 10:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.NOVEMBER.UPPER.CASE');
        break;
      case 11:
        nameMonth = this.translateService.instant('TRANSLATE.MONTH.DECEMBER.UPPER.CASE');
        break;
    }

    return nameMonth;
  }

  getYear(): number {
    return this.dateAttachment.getFullYear();
  }

  buttonNext() {

    this.submitted = true;
    if (this.contractSignatureForm.invalid) {
      return;
    }
    if (this.invalidSignature) {
      return;
    }

    this.setSignatureImgBase64();
    this.save();
  }

  downloadPDF2() {
    var element = document.getElementById('pdfSignatureMethod');
    var opt = {
      margin: 0,
      filename: 'TERMO_CONTRATACAO_GARANTIA_ESTENDIDA_REDUZIDA.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save();
  }

  save(): void {
    this.isSubmitted = true;
    this.blockedUi = true;

    this.contractService
      .save(this.setContractSignature())
      .subscribe((response: CreateContractSignature) => {

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('TRANSLATE.SUCCESS'),
          detail: this.translateService.instant('TRANSLATE.CONTRACT.CREATED.SUCCESSFULL')
        });

        this.chassiValidation = new ChassiValidation();
        this.choosePlanBuyng = new ChoosePlanBuyng();
        this.clientData = new ClientData();
        this.contractSummary = new ContractSummary();
        this.createContractSignature = new CreateContractSignature();
        this.contractSignature = new ContractSignature;

        this.contractIdDownload = response.contractId;
        this.isContractCreated = true;
        this.blockedUi = false;
      },
        (err) => {

          this.messageExceptionService.getMessageException(
            err.status,
            this.translateService.instant('TRANSLATE.CONTRACT.COULD.NOT.SAVED.CLOSE.PAGE.LOG.AGAIN')
          );

          this.isSubmitted = false;
          this.blockedUi = false;
          this.isContractCreated = false;
        });
  }

  nationalId(cpfCnpj: string): string {
    if (cpfCnpj.length == 11) {
      return 'PF';
    } else {
      return 'PJ';
    }
  }

  contractStartDate(): Date {
    let date = new Date();
    if (this.chassiValidation.warrantyStartDate != null) {
      date = this.chassiValidation.warrantyStartDate;
    }
    return date;
  }

  validateSignature(): boolean {
    let retorno = true;
    if (!this.signaturePad.isEmpty()) {
      retorno = false;
    }
    return retorno;
  }

  setSignatureImgBase64() {
    this.signatureImgBase64 = this.signaturePad.toDataURL();
  }

  private setContractSignature(): CreateContractSignature {

    let createContractSignature = new CreateContractSignature();

    createContractSignature.contractId = this.contractSignature.contractId;
    createContractSignature.user = "EMISSAO";
    createContractSignature.country = this.country;

    createContractSignature.files = this.setFileSignature(createContractSignature.files);

    return createContractSignature;
  }

  setFileSignature(files: FileFull): FileFull {

    if (this.country === ConstantsFordProtect.BRA && this.productCode === ConstantsFordProtect.PREB) {
      files.type = ConstantsFordProtect.TERMO_CONTRATACAO;
      files.name = "Ford Protect Termo de Contratacao";
      files.description = "TERMO DE CONTRATAÇÃO - GARANTIA ESTENDIDA REDUZIDA";
      files.fileReference = ConstantsFordProtect.TERMO_CONTRATACAO.toString();
      files.file = this.signatureImgBase64;
    } else if (this.country === ConstantsFordProtect.CHL) {
      files.type = ConstantsFordProtect.CONTRATACAO_SUCESSO_CHL;
      files.name = "Ford Protect Termo Adesao Plano Manutencao";
      files.description = "TÉRMINOS Y CONDICIONES DEL PLAN DE MANTENIMIENTO PREPAGO FORD PROTECT";
      files.fileReference = ConstantsFordProtect.CONTRATACAO_SUCESSO_CHL.toString();
      files.file = this.signatureImgBase64;
    } else if (this.country === ConstantsFordProtect.COL) {
      files.type = ConstantsFordProtect.CONTRATACAO_SUCESSO_COL;
      files.name = "Ford Protect Termo";
      files.description = "TÉRMINOS Y CONDICIONES DEL PLAN DE MANTENIMIENTO PREPAGO FORD PROTECT";
      files.fileReference = ConstantsFordProtect.CONTRATACAO_SUCESSO_COL.toString();
      files.file = this.signatureImgBase64;
    }

    return files;
  }

  downloadPDF() {
    this.blockedUi = true;
    this.contractService.downloadFile(this.contractIdDownload)
      .subscribe((response: DownloadFile) => {
        const source = response.pdfByteArray;
        const link = document.createElement("a");
        link.href = source;
        link.download = `Termos do Contrato.pdf`
        link.click();

        this.blockedUi = false;
      },
        (err) => {
          this.blockedUi = false;
          this.messageExceptionService.getMessageException(err.status, 'Arquivo não encontrado.');
        });
  }

}
