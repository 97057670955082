import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { SaleChannel } from 'src/app/models/enum/sale-channel';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ChassiValidationService } from 'src/app/services/chassi-validation/chassi-validation.service';
import { ChoosePlanService } from 'src/app/services/choose-plan/choose-plan.service';
import { ClientDataService } from 'src/app/services/client-data/client-data.service';
import { ContractSummaryService } from 'src/app/services/contract-summary/contract-summary.service';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';
import { VehicleInformationService } from 'src/app/services/vehicle-information/vehicle-information.service';

@Component({
	selector: 'app-sales-channel-external',
	templateUrl: './sales-channel-external.component.html',
	styleUrls: ['./sales-channel-external.component.css']
})
export class SalesChannelExternalComponent implements OnInit {

	isChassi: boolean = true; // vai ficar true ate definir a regra de negocio sem chassi
	chassi: string = "";
	chassiValidation: ChassiValidation;
	inputChassiStyleCss: String = "width: 200px; height: 34px; font-size: 16px;";
	isValidChassi: number = StatusChassi.valid;
	saleChannel: String = SaleChannel.EXTERNAL;
	isPermission: boolean = false;
	errorMessageVin: string;

	constructor(private chassiValidationService: ChassiValidationService,
		private vehicleInformationService: VehicleInformationService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private apsPermissionService: ApsPermissionService,
		private choosePlanService: ChoosePlanService,
		private clientDataService: ClientDataService,
		private contractSummaryService: ContractSummaryService,
		private messageExceptionService: MessageExceptionService
	) {
	}

	ngOnInit() {
		this.isPermission = this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenContractSearch);
		if (this.isPermission) {
			this.vehicleInformationService.getAllChassiValidationClean();
			this.choosePlanService.getChoosePlanBuyngClean();
			this.clientDataService.getClientDataClean();
			this.contractSummaryService.getContractSummaryClean();
		}
	}

	buttonNext() {
		this.messageService.clear();
		if (this.chassiValidation && this.chassiValidation.validated == StatusChassi.valid) {
			this.router.navigate(['vehicle-information', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
		} else {
			this.messageService.add({ severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'), detail: this.translateService.instant('TRANSLATE.CHASSI.INVALID') });
		}
	}

	onKeyUp(event: KeyboardEvent): void {
		this.chassi = this.chassi.toUpperCase();
		if (event.key !== "Control" && this.chassi.length === 17) {
			this.buttonSearchChassi();
		}
	}

	buttonSearchChassi() {
		this.messageService.clear();
		this.chassiValidationService.getAllChassiValidationListClean();

		if (this.chassi !== '') {
			this.searchChassiSaveAndVector(this.chassi);
		} else {
			this.messageService.add({ severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'), detail: this.translateService.instant('TRANSLATE.ENTER.CHASSIS.CORRECTLY') });
		}

	}

	searchChassiSaveAndVector(chassi: string) {
		this.messageService.clear();
		let country: String = 'BRA';

		this.chassiValidationService.getChassiValidation(country, chassi, "pt-BR")
			.subscribe((response: ChassiValidation) => {
				this.chassiValidation = this.verifyChassiValidation(response);
				this.chassiValidation.vin = chassi;
				this.errorMessageVin = this.chassiValidation.errorMessage;

				if (this.chassiValidation.validated == StatusChassi.valid
					&& this.errorMessageVin !== 'VIN.ERROR.SAVE_VECTOR') {

					this.messageService.add({
						severity: 'success',
						summary: this.translateService.instant('TRANSLATE.SUCCESS'),
						detail: this.translateService.instant('TRANSLATE.VEHICLE.FOUND')
					});
					this.vehicleInformationService.setAllChassiValidation(this.chassiValidation);
					this.inputChassiStyleCss = this.inputChassiStyleCss + ' border: 2px solid #0c8e1c;';

				}
				else {

					this.inputChassiStyleCss = this.inputChassiStyleCss + ' border: 2px solid #ec2626;';
					this.messageService.add({
						severity:'error', 
						summary: this.translateService.instant('TRANSLATE.ERROR'), 
						detail: this.translateService.instant('TRANSLATE.VEHICLE.NOT.FOUND.TYPE.AGAIN')
					});
				}
			}, 
			(err) => {
				
				this.chassiValidation = null;
				if (err.status == 400) {
					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.VEHICLE.NOT.FOUND.TYPE.AGAIN')
					});
				}
				else {
					this.messageExceptionService.getMessageException(err.status, this.translateService.instant('TRANSLATE.VEHICLE.NOT.ACCESS.LOGIN.AGAIN'));
				}
			});
	}

	verifyChassiValidation(chassiValidation: ChassiValidation): ChassiValidation {

		if (chassiValidation.errorMessage != null && chassiValidation.errorMessage.trim().toUpperCase() === 'TRANSLATE.ERROR.SAVE_VECTOR') {
			chassiValidation.validated = StatusChassi.invalid;
		} else {
			chassiValidation.validated = StatusChassi.valid;
		}

		return chassiValidation;
	}
}
